<template>
  <div class="inboxList">
    <b-field>
      <b-input v-model="search" icon="search" placeholder="Find a previous chat " type="search"></b-input>
    </b-field>
    <div
      v-for="interlocutor in filteredInterlocutors"
      :key="interlocutor.handle"
      :class="{ selected: interlocutor.handle == $route.params.bot }"
    >
      <router-link :to="`/messages/${interlocutor.handle}`">
        <div class="media">
          <div class="media-left">
            <figure class="image is-64x64">
              <img :src="interlocutor.avatar" alt="" class="avatar" />
            </figure>
          </div>
          <div class="media-content">
            <p class="title is-6">{{ interlocutor.handle }}</p>
            <p class="subtitle is-6 is-size-7-mobile is-ellipsis-2 elep1">
              {{ interlocutor?.latestMsg?.text || interlocutor.bio }}
            </p>
          </div>
        </div>
      </router-link>

      <!-- 
          {
    t: "add yours",
    a: "plus.webp",
    d: "Design your own bot, and share it with the world", // "Launch a bot for your brand, or just for fun.   ",
  },

      -->
    </div>

    <!--    <router-link :to="`/create`">
      <div class="media">
        <div class="media-left">
          <figure class="image is-64x64">
            <img src="/img/bots/plus.webp" alt="" class="avatar" />
          </figure>
        </div>
       
        <div class="media-content">
          <p class="title is-6">Add yours...</p>
          <p class="subtitle is-6 is-size-7-mobile is-ellipsis-2 elep1">
            "Design your own bot, and share it with the world
          </p>
        </div>
      
      </div>
    </router-link>  -->

    <router-link :to="`/explore`">
      <div class="media">
        <div class="media-left">
          <figure class="image is-64x64">
            <img src="https://only-bots.com/img/bots/plus.webp" alt="" class="avatar" />
          </figure>
        </div>
        <div class="media-content">
          <p class="title is-6">Explore...</p>
          <p class="subtitle is-6 is-size-7-mobile is-ellipsis-2 elep1">Fun and helpful chatbots</p>
        </div>
      </div>
    </router-link>
  </div>
</template>
<script>
const explorePromo = {
  title: "Explore...",
  url: "/explore",
  bio: "Meet more bots and interact with them",
};

export default {
  props: {
    interlocutors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    filteredInterlocutors() {
      var ar = this.interlocutors;
      //append explorePromo to the list of interlocutors
      if (this.search === "") {
        //    ar.push(explorePromo);
      }
      if (this.search === "") {
        return this.interlocutors;
      } else {
        //todo adjust to also include tags? or use an API call?
        // let the server return a list of keywords so it's always searchable in here as interlocutors.

        const query = new RegExp(this.search, "i");
        return this.interlocutors.filter((interlocutor) => {
          return query.test(interlocutor.handle);
        });
      }
    },
  },
};
</script>

<style scoped>
.media {
  padding: 3px;
  border-radius: 8px;
}

.media-content {
  overflow-x: inherit;
}

.title {
  font-size: 14px;
}
.subtitle {
  font-size: 14px;
}
.selected .media {
  background-color: #d8fff9;
}
.avatar {
  width: 64px;
  height: 64px;
  padding: 3px;
  border-radius: 50%;
}

.media {
  align-items: center;
}
</style>
