<template>
  <div class="bubble-line">
    <!--  -->

    <div class="text-align-center time" v-if="showTime">{{ prettyMs(date) }} {{ formatBoldHighlight }}</div>

    <img v-if="!isUser" :src="avatar" alt="" class="bubble-avatar avatar" />

    <div
      :class="{
        'chat-bubble': true,
        'chat-bubble-user': isUser,
        'chat-bubble-bot': !isUser,
        shortText: shortText,
        formatBoldHighlight: formatBoldHighlight && !isUser,
      }"
      :style="bubbleInlineStyle"
    >
      <div v-if="bubbleType === 'text'" class="chat-bubble-text">
        <!--  -->
        <p v-html="textHtml"></p>
      </div>
      <div v-else-if="bubbleType === 'code'" class="chat-bubble-code">
        <div v-html="renderedMarkdown"></div>
        <!-- 
        <pre><code>{{ text }}</code></pre> -->
      </div>
      <div v-else-if="bubbleType === 'markdown'" class="chat-bubble-markdown content">
        <div v-html="renderedMarkdown"></div>
      </div>
      <div v-else-if="bubbleType === 'image'" class="chat-bubble-image">
        <img :src="text" alt="Chat image" />
      </div>
      <div v-else-if="bubbleType === 'url'" class="chat-bubble-url">
        <a :href="text" target="_blank">{{ text }}</a>
      </div>
      <!-- 
    <span class="message-time">{{ time }}</span>
    --></div>
  </div>
</template>

<script>
import marked from "marked";
//const extendedTables = require("marked-extended-tables");
//import extendedTables from "marked-extended-tables/lib/index.umd.js";
//import extendedTables from "marked-extended-tables/lib/index.cjs";

// or ES Module script
// import marked from "https://cdn.jsdelivr.net/gh/markedjs/marked/lib/marked.esm.js";
// import this extension from "https://cdn.jsdelivr.net/gh/calculuschild/marked-extended-tables/lib/index.mjs";

//console.log(extendedTables, 4859023584039850943);
//marked.use(extendedTables());

// MARKED OPTIONS - move to another file if needed elsewhere.
// myMarked.js
var renderer = new marked.Renderer();
renderer.link = function (href, title, text) {
  var link = marked.Renderer.prototype.link.call(this, href, title, text);
  return link.replace("<a", "<a target='_blank' ");
};

marked.setOptions({
  renderer: renderer,
});

import { elipsis, timeAgo, prettyMs } from "@/common/utils";

function containsMarkdownTable(s) {
  //const pattern = /\|[^|]+\|\s*\n\|[-:]+(\|[-:]+)*\|\s*\n(\|[^|]+\|\s*\n)*/g;
  const pattern = /\|[^|]+\|\s*\n\|\s*[-:]+\s*(\|\s*[-:]+\s*)*\|(\s*\n(\|[^|]+\|\s*)*\n)*/g;

  return pattern.test(s);
}

import { convertToHex } from "@/libs/color.js";

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    isUser: {
      type: Boolean,
      default: false,
    },
    date: {
      type: Date,
      default: Date.now(),
    },
    avatar: {
      type: String,
      default: "",
    },
    showTime: {
      type: Boolean,
      default: false,
    },
    bubbleColor: {
      type: Object,
      // default: {}, // "#ff0000",
    },
    bubbleTextColor: {
      type: String,
      default: "white",
    },
    formatBoldHighlight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    time() {
      var d = new Date(this.date);
      var n = d.toLocaleTimeString();
      return n;
    },
    renderedMarkdown() {
      //  return marked.parse("# Marked in the browser\n\nRendered by **marked**.");
      var str = String(this.text);
      return marked.parse(str);
    },
    textHtml() {
      var str = String(this.text);

      //make links.
      if (str.includes("http")) {
        //replace with link - but only in instance where it's text. maybe move this to bubbel?
        str = str.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>');
      }
      return str;
      //Just basic text augmentation, like links...
    },
    shortText() {
      return this.text.length < 120;
    },
    bubbleInlineStyle() {
      if (!this.isUser) return "";
      //User bubble only
      var defaultColor = "blue"; //"#f1f1f1";
      var defaultTextColor = "white"; //"#f1f1f1";
      var color = defaultColor;
      var textColor = defaultTextColor; //
      if (this.bubbleColor) {
        color = convertToHex(this.bubbleColor);
        textColor = this.bubbleTextColor || defaultTextColor;
      }

      return {
        "background-color": color || defaultColor,
        color: textColor,
      };
    },
    bubbleType() {
      if (this.isImageURL(this.text)) {
        return "image";
      } else if (this.isCode(this.text)) {
        return "code";
      } else if (this.isMarkdown(this.text)) {
        return "markdown";
      } else if (this.isURL(this.text)) {
        return "url";
      } else {
        return "text";
      }
    },
  },
  methods: {
    timeAgo: function (date) {
      return timeAgo(date);
    },
    prettyMs: function (date) {
      return prettyMs(date);
    },

    isImageURL(url) {
      return /\.(png|jpe?g|gif)$/i.test(url);
    },
    isMarkdown(text) {
      // Use a regex to check if the text contains markdown syntax

      // check if string contains ## or ##
      // const markdownRegex = /(^|\s)(\*{1,2})([^\n*]+)\2($|\s)/gm;

      //if (text.includes("##") || text.includes("###")) return true;

      let hasHashes = text.split("\n").some((line) => line.startsWith("##") || line.startsWith("###"));
      if (hasHashes) return true;

      if (containsMarkdownTable(text)) return true;

      const markdownRegex = /(^|\s)(\*{1,2})([^\n*]+)\2($|\s)/gm;
      return markdownRegex.test(text);
    },
    isCode(text) {
      // Use a regex to check if the text contains code syntax
      return this.text.indexOf("```") > -1;
      const codeRegex = /(^|\s)(`{1,3})([^\n`]+)\2($|\s)/gm;
      return codeRegex.test(text);
    },
    isURL(text) {
      // Use a regex to check if the text contains a valid URL
      const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
      return urlRegex.test(text);
    },
  },
};
</script>

<style>
.systemUi,
.chat-bubble {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.bubble-line {
  margin-top: 10px;
}
.bubble-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 1px;
}
.chat-bubble {
  display: inline-block;
  margin: 0.5rem;
  margin: 0;
  padding: 0.6rem 1rem;
  max-width: 300px;
  word-wrap: break-word;
  border-radius: 18px; /* fb messanger*/
  position: relative;
  font-size: 15px;
  line-height: 20px;
}

/* larger bubble or desktop screen. */
@media (min-width: 800px) {
  .chat-bubble {
    max-width: 450px;
  }
  .chat-bubble.shortText {
    max-width: 300px;
  }
}

@media (min-width: 1400px) {
  .chat-bubble {
    max-width: 600px;
  }
  .chat-bubble.shortText {
    max-width: 400px;
  }
}

.chat-bubble strong {
  font-weight: bold !important;
}

.chat-bubble.formatBoldHighlight {
  line-height: 2em;
}
.chat-bubble.formatBoldHighlight strong {
  color: #9200cf;
  color: #005838;
  border-bottom: 2px solid #03ffa4;
}

.chat-bubble-text {
  white-space: pre-line;
}

.chat-bubble.shortText {
  max-width: 300px;
}

.chat-bubble::after {
  /*
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 0.8rem 0.8rem 0 0;
  border-color: transparent #f5f5f5 transparent transparent;
  top: 0.8rem;
  transform: rotate(45deg);
  right: -0.8rem;
  */
}

.chat-bubble-user {
  background-color: blue;
  color: white;
  float: right;
}

.chat-bubble-bot {
  background-color: red;
  background-color: rgb(228, 230, 234);
  color: black;
  /* float: left;*/
}
</style>
