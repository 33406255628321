var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bubble-line"},[(_vm.showTime)?_c('div',{staticClass:"text-align-center time"},[_vm._v(_vm._s(_vm.prettyMs(_vm.date))+" "+_vm._s(_vm.formatBoldHighlight))]):_vm._e(),_vm._v(" "),(!_vm.isUser)?_c('img',{staticClass:"bubble-avatar avatar",attrs:{"src":_vm.avatar,"alt":""}}):_vm._e(),_vm._v(" "),_c('div',{class:{
      'chat-bubble': true,
      'chat-bubble-user': _vm.isUser,
      'chat-bubble-bot': !_vm.isUser,
      shortText: _vm.shortText,
      formatBoldHighlight: _vm.formatBoldHighlight && !_vm.isUser,
    },style:(_vm.bubbleInlineStyle)},[(_vm.bubbleType === 'text')?_c('div',{staticClass:"chat-bubble-text"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.textHtml)}})]):(_vm.bubbleType === 'code')?_c('div',{staticClass:"chat-bubble-code"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.renderedMarkdown)}})]):(_vm.bubbleType === 'markdown')?_c('div',{staticClass:"chat-bubble-markdown content"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.renderedMarkdown)}})]):(_vm.bubbleType === 'image')?_c('div',{staticClass:"chat-bubble-image"},[_c('img',{attrs:{"src":_vm.text,"alt":"Chat image"}})]):(_vm.bubbleType === 'url')?_c('div',{staticClass:"chat-bubble-url"},[_c('a',{attrs:{"href":_vm.text,"target":"_blank"}},[_vm._v(_vm._s(_vm.text))])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }