<template>
  <form
    class="comment-box"
    :class="{ hasTyped: hasTyped }"
    style="display: block"
    method="post"
    @submit.prevent="postForm"
  >
    <div
      class="columns is-vcentered is-variable is-1 is-mobile is-align-items-flex-startNO"
      style="padding: 0; margin: 0"
    >
      <div class="column">
        <textarea-autosize
          placeholder="Type something here..."
          v-model="commentText"
          :min-height="isMobile ? 18 : 50"
          :max-height="isMobile ? 90 : 250"
          :loading="sending"
          :disabled="sending"
          class="input"
          name="commentText"
          style="width: 100%; background: #fafafa"
          @input.native="commentChange"
          @keyup.enter.native="enterKey"
        />
      </div>
      <div class="column is-narrow sendCol">
        <b-button
          type="submit"
          :loading="sending"
          :disabled="disableSend || sending || showTooManyMessagesError"
          class="btnSend is-hidden-touch is-info"
          native-type="submit"
          value="Submit input"
          style="margin-left: 10px"
        >
          <span class="icon has-text-infoNOT">
            <i class="fal fa-paper-plane"></i>
          </span>
          <span class=""> send </span>
        </b-button>
        <b-button
          type="submit"
          :loading="sending"
          :disabled="sending || showTooManyMessagesError"
          class="btnSend is-info is-hidden-desktop"
          native-type="submit"
          value="Submit input"
          outlined
          style="margin-right: 0; padding: 5px; font-size: 25px; border: 0; margin-left: 18px"
        >
          <span class="icon has-text-infoNOT">
            <i class="fas fa-paper-plane"></i>
          </span>
        </b-button>
      </div>
    </div>
    <!-- 
    <hr />
 
    {{ commentText }} ==commentText model!
    <br />
    {{ hasTyped }} ==hasTyped

    <b-button @click="commentText = 123" label="clear" />
         -->
  </form>
</template>

<script>
//import TextareaAutosize from "vue-textarea-autosize";
import TextareaAutosize from "vue-textarea-autosize";

import Vue from "vue";
//import TextareaAutosize from "vue-textarea-autosize";

Vue.use(TextareaAutosize);

export default {
  components: {
    //TextareaAutosize,
  },
  props: {
    id: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    disableSend: {
      type: Boolean,
      required: false,
    },
    convoKey: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      commentText: "", //ISSUE IS HERE! - not updating, because it's NOT REACTIVE. should be a prop?
      sending: false,
      showTooManyMessagesError: false,
      isMobile: false, // set to true or false depending on the viewport size
    };
  },
  updated() {
    //fire on route change - all of em!
    // this.initInput(); //scroll to bottom...
  },
  mounted() {
    this.initInput();
    // this.scrollBottom(); //scroll to bottom...
    this.isMobile = window.innerWidth < 768;
  },
  computed: {
    hasTyped() {
      return this.commentText.length > 0;
    },
  },

  methods: {
    initInput() {
      //init the input
      if (this.convoKey) {
        // RETRIEVE SAVED DRAFT
        console.log("RETRIVE FIELD DRAFT.");
        console.log("RETRIEVE KEY!", this.convoKey);
        //this.commentText = localStorage.getItem(this.convoKey);
        //THIS IS BUGGY, IT PRevent form from clearing when submiting, reload auto old stuff...
      }
    },
    enterKey(evt) {
      console.log("ENTER PRESSED");
      /*
      if (evt.keyCode == 13 && !evt.shiftKey) {
        console.log("RELLL ONLY");
        this.postForm();
      }*/

      if (evt.shiftKey === true && evt.key === "Enter") {
        console.log("New line added, message not sended");
        return;
      } else {
        console.log("enter without shift, send msg");
        this.postForm();
      }
      // console.log("Message sended");
    },
    commentChange: function (evt) {
      //   alert(3);
      //   return true; //necesaty
      console.log("commentChange. SAVE DRAFT: ", this.commentText);
      if (this.convoKey) {
        //this.commentText &&
        localStorage.setItem(this.convoKey, this.commentText);
      }
    },
    /*
    scrollBottom: function () {
      var container = document.getElementById("messageScroll"); //no working with bad ids...
      container.scrollTop = container.scrollHeight;
    },*/
    async postForm() {
      var me = this;
      // your logic here
      var s = String(this.commentText);
      var commentText = s.trim();
      if (commentText) {
        //NOTE: dont return false.

        //emit the @send event for parent
        this.commentText = ""; // Not always working...
        await localStorage.setItem(this.convoKey, "NO!");
        await localStorage.removeItem(this.convoKey);
        this.commentText = ""; // Not always working...
        console.log("cleared local storage...:::");
        this.$emit("send", commentText);

        //clear it from form + memory.
      }
    },
  },
};
</script>

<style scoped>
/* your styles here */
</style>
