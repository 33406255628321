<template>
  <div class="message-thread">
    <div class="theCol">
      <div class="theScroll">
        <div class="intro-card">
          <div class="message-avatar">
            <router-link :to="`${bot.url}`">
              <img v-if="task?.image && !convo?.isGeneralChat" :src="task?.image || bot.avatar" alt="" class="avatar" />
              <img v-else :src="bot.avatar" alt="" class="avatar" />
            </router-link>
          </div>

          <jsonDebug :data="convo" label="convo" /> <jsonDebug :data="task" label="TAsk" />
          <div class="message-handle">
            <h2 class="title is-6" style="margin-bottom: 4px">
              {{ bot.handle }}
              <span v-if="task && !convo?.isGeneralChat"> • {{ task.label }} </span>
            </h2>
            <!--  
        <p class="subtitle is-6">{{ bot.bio }}</p>-->
            <router-link v-if="isOwner" :to="`/bots${bot.url}`" class="button is-small is-rounded">
              Settings
            </router-link>

            <!-- 
            <router-link
              :to="`/shared/convo/${bot.handle}/${convo?.publicConvoKey}`"
              class="button is-light is-small is-rounded"
             
            >
              Share
            </router-link> -->

            <div
              v-if="!isOwner && convo?.publicConvoKey && !$route.params.publicConvoKey"
              class="button is-small is-rounded"
              @click="sharePublicLink"
            >
              Share
            </div>
          </div>
        </div>

        <div class="historyPane" v-if="isHistory">
          <div class="list-history">
            <br />
            <router-link
              v-for="(c, cIndex) in convos"
              :key="cIndex + 'conta3ct'"
              :to="c.url || `/messages/${bot.handle}/${c.id}?hideExtra=1`"
              replace
              class="historyItem"
              :class="{ 'is-outlined  ': convo?.id != c.id }"
            >
              <div class="box" :class="{ current: convo && c.id == convo?.id }" style="margin: 5px">
                <p class="a elep1" style="max-width: 300px">{{ c.lastMessage?.text }}</p>
                <p class="t" style="float: right; opacity: 0.3">{{ `  ${c.id}` }}</p>
                <p class="small" style="opacity: 0.5; font-size: 13px">{{ timeAgo(c.updated) }}</p>
              </div>
            </router-link>
          </div>
        </div>

        <div class="pill-links" v-if="showPills && !isHistory">
          <!--  previous convos -->
          <h3 class="title is-4"></h3>

          <!--  

            Old sliding conversation with ids..
          <nav class="chatbox__navigation">
            <router-link
              :to="'/messages/' + bot.handle + '/new'"
              replace
              class="button is-info is-outlined is-small is-rounded"
            >
              <button class="switchConvo" :class="{ current: !convo || convo?.id == 'new' }">
                <p>New</p>
              </button>
            </router-link>
            <router-link
              v-for="(c, cIndex) in convos"
              :key="cIndex + 'conta3ct'"
              :to="c.url || `/messages/${bot.handle}/${c.id}`"
              replace
              class="button is-info is-small is-rounded"
              :class="{ 'is-outlined  ': convo?.id != c.id }"
            >
              <button class="chatbox__channelSwitchButton" :class="{ current: convo && c.id == convo?.id }">
                <p>{{ c.title || `convo ${c.id}` }}</p>
              </button>
            </router-link>
          </nav>
-->
          <router-link :to="`/messages/${bot.handle}/history`" replace>
            <b-button
              icon="history"
              outlined
              type="is-dark"
              class="switchConvo is-hidden-touch is-dark is-small is-rounded"
              :class="{ current: isHistory }"
            >
              <p>History</p>
            </b-button>
          </router-link>
        </div>
        <div class="message-list" v-show="!isHistory">
          <div class="messageLine" v-for="(mess, mIndex) in messages" :key="mess.text + mIndex">
            <inboxBubble
              :date="new Date(mess.date)"
              :text="mess.text"
              :isUser="mess.speaker != 'bot'"
              :avatar="bot.avatar"
              :bubbleColor="bot.bubbleColor1"
              :bubbleTextColor="bot.bubbleTextColor"
              :formatBoldHighlight="bot.formatBoldHighlight"
              :showTime="mess.messageIsMoreThan12hBeforeNext"
            ></inboxBubble>

            <!-- 
        <div class="message-avatar">
          <img :src="message.userAvatar" :alt="message.userName" />
        </div>
        <div class="message-content">
          <h3>{{ message.userName }}</h3>
          <p>{{ message.text }}</p>
          <span class="message-time">{{ message.time }}</span>
        </div>
 -->
          </div>
          <div class="indicator-wrap" :class="{ active: thinking }">
            <typingIndicator class="indicator1" :class="{ active: thinking }" style="display: block" />
          </div>
          <div id="bottomThreadAnchor"></div>
        </div>
      </div>
      <div class="theBar" v-show="!isHistory">
        <div class="theInput" v-if="!shareMode">
          <inboxInput
            @send="sendMessage"
            :disableSend="disableSend"
            :convoKey="convoKey"
            :placeholder="task?.placeholder || bot?.placeholder || 'Type your message here'"
          />
        </div>

        <div v-if="shareMode" class="text-align-center">
          <br />
          <br />
          <br />
          <router-link :to="`/`">
            <img src="/img/logo.png" style="max-width: 200px; border-radius: 40px; margin: 30px auto; display: block" />
          </router-link>
          <p>
            Chat between

            <router-link :to="`${bot.url}`" class="button is-light is-small is-rounded">
              {{ bot.handle }}
            </router-link>
            and
            <router-link :to="`/u/${convo.user}`" class="button is-light is-small is-rounded">
              {{ convo.user }}
            </router-link>
          </p>
          <!-- --  only if logged out -->
          <p v-if="!$store.main.getters.isLoggedIn">
            <a href="https://only-bots.com">Try OnlyBots, talk with AIs, create your bots.</a>
          </p>
          <br />
          <br />
          <br />
        </div>
      </div>

      <!--  
    <div class="message-compose">
      <form @submit.prevent="sendMessage">
        <textarea
          :placeholder="task?.placeholder || bot?.placeholder || 'Type your message here'"
          v-model="messageText"
        ></textarea>
        <button type="submit" :disabled="loading">
          <span v-if="!loading">Send</span>
          <span v-else>Sending...</span>
        </button>
      </form>
    </div>-->
    </div>
  </div>
</template>

<script>
//     <inboxThread :bot="bot" :interlocutor="interlocutor" :convo="convo" :convos="convos"></inboxThread>

// inboxBubble.vue
import inboxBubble from "@/components/inbox/inboxBubble.vue";
import typingIndicator from "@/components/inbox/typingIndicator.vue";
import inboxInput from "@/components/inbox/inboxInput.vue";
import { elipsis, timeAgo, prettyMs } from "@/common/utils";

export default {
  name: "inboxThread",
  components: {
    inboxBubble,
    typingIndicator,
    inboxInput,
  },
  props: {
    bot: {
      type: Object,
      required: true,
    },
    isHistory: {
      type: Boolean,
      required: false,
      default: false,
    },
    newRoute: {
      type: Boolean,
      required: false,
    },

    convo: {
      type: Object,
      required: false,
    },
    convos: {
      type: Array,
      required: true,
    },
    tasks: {
      type: Array,
      required: false,
      default: () => [],
    },
    shareMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      messagesOLD: [],
      sending: false,
      thinking: false,
      disableSend: false, //while sending, disable send button
    };
  },
  //methods to send message
  methods: {
    timeAgo: function (date) {
      return timeAgo(date);
    },
    prettyMs: function (date) {
      return prettyMs(date);
    },
    sharePublicLink() {
      //navigator share, with fallback to copy to clipboard + snackabar
      const base = "https://only-bots.com";
      var sharebleUrl = base + `/shared/convo/${this.bot.handle}/${this.convo?.publicConvoKey}`;
      if (navigator.share) {
        navigator
          .share({
            title: "OnlyBots",
            text: "Chat with " + this.bot.handle,
            url: sharebleUrl, //window.location.href,
          })
          .then(() => console.log("Successful share"))
          .catch((error) => console.log("Error sharing", error));
      } else {
        //fallback to copy to clipboard
        var dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = sharebleUrl; //window.location.href;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        //buefy snackbar
        this.$buefy.snackbar.open({
          message: "Link copied to clipboard",
          type: "is-success",
          position: "is-bottom",
          duration: 3000,
          queue: false,
        });
      }
    },
    async sendMessage(txt) {
      // Check if message is not empty
      var text = String(txt).trim();
      if (!text) return;
      // APPEND THE MSG BUBBLE TO THE CONVO
      this.disableSend = true;
      console.log("sendMessage:::", text);

      // todo

      this.sending = true; // set loading state to true
      // ADD the bubble.
      this.messages.push({
        text: text,
        speaker: "user",
        date: new Date(),
      });
      //1 sec later, add the Thinking could indicator.
      this.thinking = true;
      this.scrollBottom();
      /*
      var showThinkBubbleTimout = setTimeout(() => {
        this.thinking = true;
    
      }, 100);*/

      try {
        //
        var opt = { text: text, task: this.task?.id };
        var existingConvo = this.convo?.id;
        console.log("EXISTING CONVO: existingConvo", existingConvo);

        if (existingConvo) {
          // update existing convo
          /*
            var convo = await this.$store.main.dispatch("conversations/update", {
                convoId: existingConvo,
                text,
            });*/
          this.scrollBottom();
          var replyResponse = await window.API.replyUserBotConvo(this.bot.handle, existingConvo, opt);
          //show toast!
          /*
          this.$buefy.toast.open({
            message: "Message received back!!",
            type: "is-success",
            position: "is-bottom-right",
          });*/
          //emit event to parent to reloadMsg
          //  alert(1);
          this.$emit("reloadMsg", replyResponse);
          this.scrollBottom();
        } else {
          // creating new convo
          // Possibly based on a TASK

          var insertResponse = await window.API.startUserBotConvo(this.bot.handle, opt);

          //Function returns convo ID,
          this.thinking = false;
          // router redirect to convo ID, replace
          this.$router.replace(`/messages/${this.bot.handle}/${insertResponse.convoId || "new12345688888"}`);
          /*
          var convo = await this.$store.main.dispatch("conversations/create", {
            botId: this.bot.id,
          });*/
        }

        // await new Promise((resolve) => setTimeout(resolve, 4000)); // simulate sending message delay
        // alert(this.messageText);
        // this.messageText = ""; // clear textarea after sending message
      } catch (error) {
        console.error(error, "ERROR REACHING THE SERVER =- =- =- =- =-= -=");
        //buefy snackbar
        /*
        this.disableSend = false;
        this.thinking = false;
        this.sending = false; // set loading state back to false
        */
        // remove timeout, prevent it to run
        // clearTimeout(showThinkBubbleTimout);

        this.$buefy.snackbar.open({
          message: "Error processing your message...",
          type: "is-danger",
          position: "is-top-right",
        });
      }
      this.disableSend = false;
      this.thinking = false;
      this.sending = false; // set loading state back to false
    },

    scrollBottom: function () {
      try {
        var theScroll = document.getElementById("theScroll");
        theScroll.scrollTop = theScroll.scrollHeight;
        // document.getElementById("bottomThreadAnchor").scrollIntoView({ behavior: "smooth" });
      } catch (e) {
        console.log("scrollBottom error", e); // TODO
      }
    },
  },
  computed: {
    task() {
      if (!this.tasks) return null;
      let taskId;
      if (this.convo && this.convo.task) {
        taskId = this.convo.task;
      } else {
        taskId = this.$route.params.task;
      }

      //  var para = this.$route.params.task;
      return this.tasks.find((t) => t.id == taskId);
      // return this.tasks[0]; //DEMO!
    },
    isOwner() {
      var currentUser = this.$store.main.state.userId;
      if (!currentUser) return false;
      if (!this.bot.owner) return false;
      return this.bot.owner.includes(currentUser);
    },
    convoKey() {
      var k = this.convo?.id || "new";
      return String(window.location); //this.convo?.id || "new";
    },
    messagesOLD2() {
      return this.convo?.messages || [];
    },
    messages() {
      // if (!this.tasks) return null;
      //return this.tasks[0]; //DEMO!
      var mm = this.convo?.messages || [];
      mm = mm.map((m) => {
        //m.text = "bot";
        var text = m.text;
        //add missing ```if there's an uneven number of occurence in the text
        var count = (text.match(/```/g) || []).length;
        if (count % 2 != 0) {
          text = text + "\n\n .....\n```";
        }
        m.messageIsMoreThan12hBeforeNext = false;

        m.text = text;
        return m;
      });

      return mm;
    },
    showPills() {
      if (this.$route.query.hideExtra) return false;
      return this.hasHistory;
    },
    hasHistory() {
      if (this.newRoute) return false;
      return this.convos.length >= 1;
    },
    /* newRoute() {
      return this.convos.length >= 1;
    },*/
  },
};
</script>

<style scoped>
.debug .theCol {
  border: 4px blue solid;
  border: 4px blue solid;
  padding: 5px;
}
.debug .intro-card {
  background-color: #dbdbdb;
}
.debug .theScroll {
  border: 4px magenta solid;
  background: #f3b7b7;
  padding: 5px;
}

.debug .theInput {
  background: green;
}

.theCol {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 1em 1.5em 0;
  padding: 6px;
  padding: 0;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
  margin-top: 13px;

  justify-content: space-between;
}

.theScroll {
  min-height: 100% !important;
  min-width: 99%;
  overflow-x: hidden;
  overflow: scroll;
  /*
  overflow-x: hidden;*/
}

.theBar {
  width: inherit;
}
.theInput {
  width: inherit;
  height: 100px;
  padding: 16px;
}
.theInput .columns {
  padding: 3px;
  width: inherit;
}

/* history */

.box.current {
  background: #f3b7b7;
}
.indicator-wrap {
  width: 100px;
  transform: scale(0.1);
}

.indicator-wrap.active {
  width: 100px;
  transform: scale(0.7);
}
.indicator1 {
  opacity: 0;
  float: left;
  height: 10px;
  /*-moz-animation: *;
  overflow: hidden;*/
  /* transition animate all props
   */
}
.indicator1.active {
  transition: all 0.3s ease-in-out;
  opacity: 1;
  height: 50px;
  /*
  transform: scale(0.5);*/
}

.message-list {
  vertical-align: baseline;
  display: flex;
  align-content: stretch;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: stretch;
}
.message-thread {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.intro-card {
  display: block;
  align-items: center;
  padding: 1rem;

  text-align: center;
}

img.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: auto;
}
/*
.message-avatar {
  width: 50px;
  height: 50px;
  margin-right: 1rem;
}

.message-handle h2 {
  font-size: 1.2rem;
  margin-bottom: 0;
}

.message-handle p {
  font-size: 0.8rem;
  margin-top: 0;
  color: #666;
}

.pill-links {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
  background-color: #f0f0f0;
}

.message-list {
  flex: 1;
  overflow-y: scroll;
  padding: 1rem;
}

.message {
  display: flex;
  margin-bottom: 1rem;
}

.message-avatar img {
  width: 50px;
  height: 50px;
  margin-right: 1rem;
}

.message-content h3 {
  font-size: 1rem;
  margin-bottom: 0;
}

.message-content p {
  font-size: 0.8rem;
  margin-top: 0;
  color: #666;
}

*/
</style>
